export const blogList = [
  {
    id: 2,
    title: "DHCP Sunucu Kurulumu ve Yapılandırması",
    category: "DHCP",
    subCategory: ["DHCP", "Sunucu", "IPAllocation"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    authorName: "Mertcan Çiftçi",
    authorAvatar: "/assets/images/user.jpg",
    createdAt: "June 03, 2021",
    cover: "/assets/images/DHCP.jpg",
  },
  {
    id: 3,
    title: "Active Directory ile Domain Yönetimi",
    category: "Active Directory",
    subCategory: ["Active Directory", "DomainYönetimi", "WindowsServer"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    authorName: "Mertcan Çiftçi",
    authorAvatar: "/assets/images/user.jpg",
    createdAt: "June 03, 2021",
    cover: "/assets/images/active.jpg",
  },
  {
    id: 4,
    title: "Microsoft Orchestrator Nedir ve Nasıl Kullanılır",
    category: "MicrosoftOrchestrator",
    subCategory: ["MicrosoftOrchestrator", "Otomasyon", "SüreçYönetimi"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    authorName: "Mertcan Çiftçi",
    authorAvatar: "/assets/images/user.jpg",
    createdAt: "June 03, 2021",
    cover: "/assets/images/Orchestrator.png",
  },
  {
    id: 5,
    title: "Active Directory'de Windows PowerShell Kullanarak Verimliliği Artırma",
    category: "WindowsPowerShell",
    subCategory: ["WindowsPowerShell", "ActiveDirectory", "Otomasyon"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    authorName: "Mertcan Çiftçi",
    authorAvatar: "/assets/images/user.jpg",
    createdAt: "June 03, 2021",
    cover: "/assets/images/Powershell.jpg",
  },
  {
    id: 6,
    title: "Veritabanı Yönetim Sistemlerinde (DBMS) SQL'in Rolü ve Etkisi",
    category: "SQL",
    subCategory: ["SQL", "VeritabanıYönetimi", "SistemYönetimi"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    authorName: "Mertcan Çiftçi",
    authorAvatar: "/assets/images/user.jpg",
    createdAt: "June 03, 2021",
    cover: "/assets/images/SQL.png",
  },
  {
    id: 7,
    title: "TCP/IP Ağ Protokolleri ve İşlevleri",
    category: "TCP / IP",
    subCategory: ["TCP", "IP", "AğProtokolleri"],
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    authorName: "Mertcan Çiftçi",
    authorAvatar: "/assets/images/user.jpg",
    createdAt: "June 03, 2021",
    cover: "/assets/images/TCP.jpg",
  },
];
