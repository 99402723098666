import React from "react";
import "./styles.css";
import { InstagramIcon } from "../../icons";

const Header = () => (
  <header className="home-header">
    <h2>Mertcan Çiftçi</h2>
    <h1>
      <span>“</span> Sistem Yönetimi <span>”</span>
    </h1>
    <p>
      Sistem Yönetimi ve araçları ile ilgili öğrendiğim bilgileri <br /> Blog hesabımda
      paylaşıyorum.
    </p>
  </header>
);

export default Header;
